import Vue from 'vue';
import Form from './components/Form.vue';

import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

export function createApp({ locale, messages }, config = {}) {

	const i18n = new VueI18n({ locale, messages });

	const app = new Vue({
		i18n,
		render: h => h(Form, { attrs: config }),
	});

	return { app };
}
