<template>
	<svg
		class="c-angle-trim"
		viewBox="0 0 100 100"
		:width="width"
		height="100%"
		preserveAspectRatio="none"
	>
		<polygon
			v-if="fillLeft"
			points="0,0 0,100, 100,0"
			:fill="fillLeft"
		/>
		<polygon
			v-if="fillRight"
			points="100,0 100,100, 0,100"
			:fill="fillRight"
		/>
		<line
			v-if="lineWidth"
			x1="0" y1="100"
			x2="100" y2="0"
			:stroke-width="lineWidth"
			:stroke="lineColor"
			:stroke-dasharray="lineDashArray"
			vector-effect="non-scaling-stroke"
		/>
	</svg>
</template>

<script>
export default {
	props: {
		angle: {
			type: Number,
			default: 247,
		},
		lineWidth: {
			type: String,
			default: '2',
		},
		lineColor: {
			type: String,
			default: 'black',
		},
		lineDashArray: {
			type: String,
			default: '8px 4px',
		},
		fillLeft: String,
		fillRight: String,
	},
	computed: {
		angle_radians() {
			return (this.angle % 90) * (Math.PI / 180);
		},
		width() {
			return this.$el ? this.$el.outerHeight : 100 / Math.tan( this.angle_radians );
		},
	},
};
</script>
