
import bus from '../models/eventBus';

export default function dataButton(selector) {
	const el = typeof selector === 'object' ? selector : document.querySelector(selector);

	if( ! el ) return console.warn('Attempted to create data-button for nonexistent element', el);
	if( ! el.dataset.event || ! el.dataset.data ) return console.warn('Data button is missing "event" or "data" data- attributes, skipping', el);

	// TODO: some kind of validation code here, this will fail if there's invalid JSON
	const event = el.dataset.event;
	const data = JSON.parse(el.dataset.data);

	el.addEventListener('click', e => {
		// Terrible special-case hack to get the Black Friday page working
		if( event === 'select-package' ) {
			const el = document.querySelector('.c-form-black-friday')

			el.classList.remove('c-form-black-friday--wiggle')

			setTimeout(() => el.classList.add('c-form-black-friday--wiggle'), 0)
		}

		bus.$emit(event, data);
	}, { passive: true });
};
