var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-checkbox" }, [
    _c("input", {
      ref: "input",
      staticClass: "c-checkbox__input",
      attrs: { type: "checkbox", id: _vm.id, name: _vm.name },
      domProps: { checked: _vm.checked },
      on: { change: _vm.onChange }
    }),
    _vm._v(" "),
    _c("label", { staticClass: "c-checkbox__label", attrs: { for: _vm.id } }, [
      _vm._v(_vm._s(_vm.label))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }