var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "c-product-search" }, [
    !_vm.disable_filter
      ? _c("form", { staticClass: "c-product-search__form" }, [
          _c(
            "div",
            [
              _c("h3", [_vm._v(_vm._s(_vm.__("product_search.server_type")))]),
              _vm._v(" "),
              _vm._l(
                ["high_memory", "high_performance", "high_storage"],
                function(term, i) {
                  return _c("c-checkbox", {
                    key: i,
                    attrs: {
                      id: "checkbox_" + term,
                      name: "checkbox_" + term,
                      label: _vm.__("product_search." + term),
                      checked: "true"
                    },
                    on: {
                      checked: function(value) {
                        return _vm.setSearchKeyword("type", term, value)
                      }
                    }
                  })
                }
              )
            ],
            2
          ),
          _vm._v(" "),
          _c("div", [
            _c("h3", [
              _vm._v(_vm._s(_vm.__("product_search.server_use_case")))
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "l-grid l-grid--2 l-grid--gap-1 l-grid--break-small"
              },
              [
                _c(
                  "div",
                  _vm._l(["small_business", "ecommerce"], function(term, i) {
                    return _c("c-checkbox", {
                      key: i,
                      attrs: {
                        id: "checkbox_" + term,
                        name: "checkbox_" + term,
                        label: _vm.__("product_search." + term),
                        checked: "true"
                      },
                      on: {
                        checked: function(value) {
                          return _vm.setSearchKeyword("use_case", term, value)
                        }
                      }
                    })
                  }),
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  _vm._l(["cloud_computing", "web_hosting"], function(term, i) {
                    return _c("c-checkbox", {
                      key: i,
                      attrs: {
                        id: "checkbox_" + term,
                        name: "checkbox_" + term,
                        label: _vm.__("product_search." + term),
                        checked: "true"
                      },
                      on: {
                        checked: function(value) {
                          return _vm.setSearchKeyword("use_case", term, value)
                        }
                      }
                    })
                  }),
                  1
                )
              ]
            )
          ]),
          _vm._v(" "),
          _vm.should_show_price_filter
            ? _c(
                "div",
                [
                  _c("h3", [
                    _vm._v(_vm._s(_vm.__("product_search.server_price_range")))
                  ]),
                  _vm._v(" "),
                  _vm._l(["below_100", "100_to_200", "above_200"], function(
                    term,
                    i
                  ) {
                    return _c("c-checkbox", {
                      key: i,
                      attrs: {
                        id: "checkbox_" + term,
                        name: "checkbox_" + term,
                        label: _vm.__("product_search." + term),
                        checked: "true"
                      },
                      on: {
                        checked: function(value) {
                          return _vm.setSearchKeyword("price", term, value)
                        }
                      }
                    })
                  })
                ],
                2
              )
            : _vm._e()
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "c-product-search__results" },
      [
        _vm._l(_vm.truncated_products, function(product) {
          return _c("product-search-block", {
            key: product.id,
            attrs: { product: product }
          })
        }),
        _vm._v(" "),
        _vm.truncated_products.length < _vm.selected_products.length
          ? _c("div", { staticClass: "c-product-search__show-more" }, [
              _c(
                "a",
                {
                  staticClass: "c-button",
                  on: {
                    click: function() {
                      return (this$1.show_more_toggled = !this$1.show_more_toggled)
                    }
                  }
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.__("product_search.show_more")))
                  ])
                ]
              )
            ])
          : _vm._e()
      ],
      2
    ),
    _vm._v(" "),
    _vm.state === "loaded" && !_vm.selected_products.length
      ? _c("div", [
          _c("h3", [_vm._v(_vm._s(_vm.__("product_search.no_results")))]),
          _vm._v(" "),
          _c("p", [
            _vm._v(_vm._s(_vm.__("product_search.try_more_parameters")))
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.state === "error"
      ? _c("div", [
          _c("h3", [_vm._v(_vm._s(_vm.__("product_search.unable_to_load")))]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              _vm._s(_vm.__("product_search.the_error_encountered_was")) +
                ' "' +
                _vm._s(_vm.error) +
                '"'
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }