<template>
	<section class="c-region-listing l-stack l-stack--compact">
		<div>
			<div
				class="c-form-field"
				:data-is-focused="filter_has_focus"
				:data-hide-placeholder="filter_hide_placeholder"
				style="width: auto; min-width: 20rem; display: inline-block"
			>
				<label class="c-form-field__label" for="region-list-filter">Search</label>
				<input
					class="c-form-field__input"
					type="text"
					id="region-list-filter"
					v-model="filter"
					@input="updateFilter"
					@focus="setFilterFocus"
					@blur="setFilterBlur"
				>
			</div>
		</div>

		<table class="c-table c-table--accent">
			<thead>
				<tr>
					<th>Region</th>
					<th>Country</th>
					<th>Data centre</th>
					<th>Address</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="row in rows" :key="row.address">
					<td>{{ row.region }}</td>
					<td>{{ row.country }}</td>
					<td>{{ row.data_centre }}</td>
					<td>{{ row.address }}</td>
				</tr>
			</tbody>
		</table>

		<div class="l-flex l-flex--space-between">
			<button
				class="c-button"
				@click="prev"
				:disabled="! can_go_back"
				:style="{ opacity: can_go_back ? 1 : 0.5 }"
			>« Prev</button>

			<span>
				Page {{page}} of {{pages}}
			</span>

			<button
				class="c-button"
				@click="next"
				:disabled="! can_go_forward"
				:style="{ opacity: can_go_forward ? 1 : 0.5 }"
			>Next »</button>
		</div>
	</section>
</template>

<script>
import datacentre_locations from '../data/datacentre_locations.json'

const ROW_LIMIT = 10

export default {
	props: ['table_id', 'disable_filter'],
	data() {
		return {
			page: 1,
			raw_rows: datacentre_locations.data.map(([region, country, data_centre, address]) => ({ region, country, data_centre, address })),
			filter: '',
			filter_has_focus: false,
			filter_hide_placeholder: false,
		}
	},
	methods: {
		next() {
			this.page++
		},
		prev() {
			this.page--
		},
		updateFilter() {
			this.page = 1
		},
		setFilterFocus() {
			this.filter_has_focus = true
			this.filter_hide_placeholder = true
		},
		setFilterBlur() {
			this.filter_has_focus = false
			this.filter_hide_placeholder = this.filter.length > 0

			console.log( this.filter, this.filter.length, this.filter.length > 0 )
		},
	},
	computed: {
		filtered_rows() {
			if( ! this.filter ) return this.raw_rows

			return this.raw_rows.filter(({ region, country, data_centre, address }) => {
				return region.toLowerCase().includes(this.filter) ||
					country.toLowerCase().includes(this.filter) ||
					data_centre.toLowerCase().includes(this.filter) ||
					address.toLowerCase().includes(this.filter)
			})
		},
		total_rows() {
			return this.filtered_rows.length
		},
		pages() {
			return Math.ceil(this.total_rows / ROW_LIMIT)
		},
		rows() {
			return this.filtered_rows.slice( (this.page - 1) * ROW_LIMIT, (this.page) * ROW_LIMIT )
		},

		can_go_back() {
			return (this.page - 1) >= 1
		},

		can_go_forward() {
			return ((this.page + 1) * ROW_LIMIT) <= this.total_rows + ROW_LIMIT
		},
	},
};
</script>
