// position: sticky; with cross browser compat and some improvements

const REM_SIZE = 16;

export default function affix(el) {
	const vert_offset = el.dataset.vertOffset ? parseInt(el.dataset.vertOffset) : 0;
	const vert_padding = 1.0 * REM_SIZE;

	let animation_pending = false;

	function getOffsets() {
		//el.style.transform = 'none';
		el.style.setProperty('--offset', '0');
		const {top, height} = el.getBoundingClientRect();
		const parent = el.parentNode.getBoundingClientRect();

		return {top, height, parent_top: parent.top, parent_height: parent.height, parent_bottom: parent.bottom};
	}

	function setPosition( offset ) {
		//el.style.transform = `translateY(${offset}px)`;
		el.style.setProperty('--offset', `${offset}px`);
	}

	function runFrame() {
		const {top, height, parent_top, parent_height, parent_bottom} = getOffsets();

		if( height >= parent_bottom + vert_padding )
			setPosition( (-1.0 * top) - (window.innerHeight - parent_bottom) + vert_padding );
		else if( top - vert_padding <= 0 )
			setPosition( (-1.0 * top) + vert_padding );
		
		animation_pending = false;
	}

	document.addEventListener('scroll', () => {
		if( animation_pending ) return;

		requestAnimationFrame(runFrame);

		animation_pending = true;
	}, { passive: true });
}
