<template>
	<div class="c-product-search-block">
		<div class="c-product-search-block__title">
			<h4>{{product.Name}}</h4>
			<div>
				<span
					v-if="product.Price"
					class="c-product-search-block__price"
				>
					{{price_month}} <span>/ {{__('product_search.mo')}}</span>
				</span>
				<span v-if="product.Price && price_setup"><br></span>
				<span
					v-if="price_setup"
					class="c-product-search-block__price"
				>
					{{price_setup}} <span> {{__('product_search.setup')}}</span>
				</span>
			</div>
		</div>
		<angle-trim fill-left="#636355" line-width="0"/>
		<ul class="c-product-search-block__specs">
			<li v-for="(value, key) in display_fields" :key="key" class="c-product-search-block__spec-entry">
				<span>{{key}}</span> <span>{{value}}</span>
			</li>
		</ul>
		<angle-trim line-width="2" line-color="lightgrey"/>
		<div class="c-product-search-block__cta">
			<a
				class="c-button"
				@click="showContactForm"
				:data-name="product.Name"
			>
				{{__('product_search.configure_now')}}
			</a>
		</div>
	</div>
</template>

<script>
import AngleTrim from './AngleTrim.vue';
import localize from '../mixins/localize';
import bus from '../models/eventBus';

export default {
	components: { AngleTrim },
	mixins: [localize],
	props: ['product'],
	methods: {
		showContactForm( event ) {
			// this is a complete hack to get this working in the short term
			document.querySelector('.form-block.block').scrollIntoView({ behavior: 'smooth' })
			document.querySelector('.form-block.block > .form-field:first-child > input').focus()

			bus.$emit('select-package', { name: event.target.dataset.name })
		},
	},
	computed: {
		price_month() {
			if( ! this.product.Price ) return 'N/A';
			return `${this.language === 'en' ? '€' : ''}${this.product.Price.replace(/\D/g, '')}${this.language !== 'en' ? '€' : ''}`;
		},
		price_setup() {
			if( ! this.product.setup_price || this.product.setup_price === '0' ) return null;
			return `${this.language === 'en' ? '€' : ''}${this.product.setup_price}${this.language !== 'en' ? '€' : ''}`;
		},
		display_fields() {
			if( ! this.product ) return {};
			const displayable = {};
			const blacklist = ['name', 'price', 'keywords', 'product_id', 'hdd slots', 'type', 'pardot_link', 'setup_price'];
			for( const key of Object.keys(this.product) ) {
				if( blacklist.includes(key.toLowerCase()) ) continue;
				displayable[key] = this.product[key];
			}
			return displayable;
		},
		cta_link() {
			if( this.product.pardot_link ) return this.product.pardot_link;
			return `https://www.m247.ro/portal/cart.php?a=add&pid=${this.product.product_id}&currency=1&language=${this.language === 'en' ? 'english' : 'romana'}`;
		}
	},
};
</script>
