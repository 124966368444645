
export const event_categories = {
	form_submitted: 'Form Submitted',
	postcode_tool: 'Postcode Tool',
	landing_page_forms: 'Landing Page Forms',
};

export function record(event, data = {}) {
	const dataLayer = window.dataLayer = window.dataLayer || [];

	return dataLayer.push({ event, ...data });
}

export function recordEvent(category, action, label = '', noninteractive = false) {
	if( ! Object.values(event_categories).includes(category) )
		return console.warn(`[metrics]: Can't log event with unknown type: '${category}'`);

	record('generic_event', {category, action, label, noninteractive});
}

export function recordPageview( url ) {
	record('generic_pageview', {url});
}
