<template>
	<form class="form-block block">
		<form-field
			v-for="field in fields"
			type="text"
			:field="field"
			:key="field.name"
			:ref="`field_${field.name}`"
		/>

		<div class="checkbox-group toc-confirmation">
			<span class="checkbox">
				<input
					type="checkbox"
					name="confirm_toc"
					id="confirm_toc"
					@click="toggleGDPR"/>
				<span class="tick-mark">✔</span>
			</span>
			<label for="confirm_toc" v-html="$t('gdpr.agree_to_toc')"/>
		</div>

		<button
			type="button"
			class="c-button c-button--angled"
			v-show="state === 'idle'"
			@click="submit"><span>{{ $t('buttons.submit') }}</span></button>

		<div class="overlay gdpr-checkbox" v-show="gdpr_notice_shown === true">
			<h3>{{ $t('gdpr.gdpr_agreement_prompt') }}</h3>

			<svg class="form-arrow" width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path fill-rule="evenodd" clip-rule="evenodd" d="M64.885 11a1 1 0 0 0-1-1h-19a1 1 0 0 0-1 1v37a1 1 0 0 1-1 1H31.002a1 1 0 0 0-.761 1.648l23.382 27.458a1 1 0 0 0 1.523 0l23.383-27.458A1 1 0 0 0 77.767 49H65.885a1 1 0 0 1-1-1V11z" fill="#F4DC00"/>
				<path d="M73.692 55.648l-1.142-.972 1.142.972zM48.786 83.106l-1.142.973 1.142-.973zm1.523 0l1.142.973-1.142-.973zM25.404 55.648l1.142-.972-1.142.972zM40.048 16.5h19v-3h-19v3zm.5 36.5V16h-3v37h3zm-14.383 2.5h11.883v-3H26.165v3zm23.763 26.633L26.546 54.676l-2.284 1.945 23.382 27.458 2.284-1.945zM72.55 54.676L49.167 82.133l2.284 1.946L74.834 56.62l-2.284-1.945zm-11.502.824H72.93v-3H61.048v3zm-2.5-39.5v37h3V16h-3zm2.5 36.5a.5.5 0 0 1 .5.5h-3a2.5 2.5 0 0 0 2.5 2.5v-3zm13.786 4.12c1.382-1.622.228-4.12-1.904-4.12v3a.5.5 0 0 1-.38-.824l2.284 1.945zM47.644 84.08a2.5 2.5 0 0 0 3.807 0l-2.284-1.945a.5.5 0 0 1 .761 0l-2.284 1.945zM26.165 52.5c-2.132 0-3.286 2.498-1.903 4.12l2.284-1.944a.5.5 0 0 1-.38.824v-3zm11.383.5a.5.5 0 0 1 .5-.5v3a2.5 2.5 0 0 0 2.5-2.5h-3zm21.5-36.5a.5.5 0 0 1-.5-.5h3a2.5 2.5 0 0 0-2.5-2.5v3zm-19-3a2.5 2.5 0 0 0-2.5 2.5h3a.5.5 0 0 1-.5.5v-3z" fill="#595E53"/>
			</svg>
		</div>

		<div class="overlay loading" v-show="state === 'loading'">
			<svg class="load-spinner" width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path fill-rule="evenodd" clip-rule="evenodd" d="M42 5.70898C20.9637 9.48345 5 27.8775 5 50C5 74.8528 25.1472 95 50 95C74.8528 95 95 74.8528 95 50C95 27.8775 79.0363 9.48345 58 5.70898V15.9185C73.4748 19.5368 85 33.4225 85 50C85 69.33 69.33 85 50 85C30.67 85 15 69.33 15 50C15 33.4225 26.5252 19.5368 42 15.9185V5.70898Z" fill="#F4DC00"/>
			</svg>
			<h3>{{ $t('loading') }}</h3>
		</div>

		<div class="overlay error" v-show="state === 'error'">

			<template v-if="typeof last_error === 'string'">
				<h3>{{ $t('errors.something_went_wrong') }}</h3>
				<div class="caption">{{ last_error }}</div>
			</template>

			<template v-else-if="last_error !== null && typeof last_error === 'object'">

				<template v-if="last_error.type === 'ERR_VALIDATION'">
					<h3>{{ $t('errors.validation_error') }}</h3>

					<div class="caption">{{ $t('errors.validation_error_caption') }}</div>

					<ul v-if="last_error.errors">
						<li v-for="error in last_error.errors" :key="error.field">
							<strong>{{error.field}}</strong> {{error.error}}
						</li>
					</ul>
				</template>

				<template v-if="last_error.type === 'ERR_SF'">

					<template v-if="last_error.name === 'DUPLICATES_DETECTED'">
						<h3>{{ $t('errors.duplicate_lead') }}</h3>

						<div class="caption">{{ $t('errors.duplicate_lead_caption') }}</div>

						<div>{{ $t('errors.duplicate_lead_subtitle') }}</div>
					</template>
					<template v-else>
						<h3>{{ $t('errors.system_error') }}</h3>

						<div class="caption">{{last_error.message}}</div>
					</template>

				</template>

				<template v-if="last_error.type === 'ERR_RATELIMIT'">
					<h3>{{ $t('errors.rate_limit_error') }}</h3>

					<div class="caption">{{ last_error.message }}</div>

					<div class="caption" v-if="last_error.retry_after">
						{{ $t('errors.rate_limit_error_caption', [last_error.retry_after]) }}
					</div>
				</template>

			</template>

			<template v-else>
				<h3>{{ $t('errors.unknown_error') }}</h3>

				<div class="caption">
					{{ last_error }}
				</div>
			</template>

			<button type="button" @click="reset">{{ $t('buttons.try_again') }}</button>
		</div>

		<div class="overlay success" v-show="state === 'success'">
			<h3>{{ $t('success.success_title') }}</h3>
			<div class="caption">{{ $t('success.success_caption') }}</div>
		</div>
	</form>
</template>

<script>
import FormField from './FormField.vue';
import formState from '../models/formState';
import bus from '../models/eventBus';

export default {
	components: {
		'form-field': FormField,
	},
	data() {
		return formState({
			components: this.$attrs.components ? this.$attrs.components.form : {},
			site_name: this.$attrs.site_name,
			locale: this.$attrs.locale,
			lead_destination: this.$attrs.lead_destination,
		});
	},
	mounted() {
		bus.$on('select-package', data => {
			this.scrollIntoView();

			if( this.$refs.field_name && this.$refs.field_name[0] ) {
				this.$refs.field_name[0].focus()
			}
			
			this.fields.enquiry.set( this.$t('package_msg', [data.name]) );
		});
	},
	methods: {
		scrollIntoView() {
			this.$el.scrollIntoView({ behavior: 'smooth', block: 'start' });
		},
		toggleGDPR() {
			this.functions.toggleGDPRAcceptance();
		},
		submit() {
			this.functions.submit();
		},
		reset() {
			this.state = 'idle';
		},
	}
};
</script>
