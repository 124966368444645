
// This is a stand-alone UI component, which needs to be able to be dropped in as-is to the RO and UK sites,
// plus potentially others later.  Therefore, it relies on the lighter-weight lit-html, instead of Vue, and
// uses the shadow dom to create its own scoped styles.
//
// Right now, navigation destinations are just being hard-coded into the UI, as this JS file will basically
// act as the 'single source of truth' as far as which services are internationalized, and accessible from
// here.  Pretty jankey, but it saves on a ton of infrastructure setup.
//
// This is fundamentally intended as only a short-term solution -- it'll be replaced by something more
// comprehensive when the new site goes live.

import {html, render} from 'lit-html';

export default function regionSelector( target ) {

	const state = {
		// A hard-coded list of available regions for the UI
		regions: [
			{
				name: 'United Kingdom',
				locale: 'en',
				icon: 'gb',
				preserve_path: false,
				default_lang: 'en',

				languages: {
					en: {
						name: 'English',
						title: 'Business connectivity in the UK',
						content: 'Extensive features, focused on connectivity, cloud infrastructure, and web hosting',
						button_text: 'Visit UK site',
						url: 'https://m247.com',
					},
				}
			},
			{
				name: 'Romania',
				locale: 'ro',
				icon: 'ro',
				preserve_path: false,
				default_lang: 'en',

				languages: {
					en: {
						name: 'English',
						title: 'Business connectivity in Romania',
						content: 'Extensive features, focused on connectivity, cloud infrastructure, and web hosting',
						button_text: 'Visit RO site',
						url: 'https://m247.ro/en/',
					},
					ro: {
						name: 'Romana',
						title: 'Conectivitate de afaceri în Romania',
						content: 'Functii extinse, axate pe conectivitate, infrastructura cloud si web hosting',
						button_text: 'Accesati site-ul RO',
						url: 'https://m247.ro/ro/',
					},
				},
			},
			{
				name: 'Spain',
				locale: 'es',
				icon: 'es',
				preserve_path: false,
				default_lang: 'en',
				
				languages: {
					en: {
						name: 'English',
						title: 'Business connectivity in Spain',
						content: 'Extensive features, focused on connectivity, cloud infrastructure, and web hosting',
						button_text: 'Visit ES site',
						url: 'https://m247.com/es-en/',
					},
					es: {
						name: 'Español',
						title: 'Conectividad empresarial en España',
						content: 'Amplias funciones, enfocadas en conectividad, infraestructura en la nube y alojamiento web',
						button_text: 'Visite el sitio de ES',
						url: 'https://m247.com/es/',
					},
				},
			},
			{
				name: 'United States',
				locale: 'en',
				icon: 'us',
				preserve_path: false,
				default_lang: 'en',

				languages: {
					en: {
						name: 'English',
						title: 'Business connectivity in America',
						content: 'Extensive features, focused on connectivity, cloud infrastructure, and web hosting',
						button_text: 'Visit US site',
						url: 'https://m247.com/us/',
					},
				},
					
			},
		],

		open: false,			// Is the menu open?
		active_section: null,	// What area is currently hovered over?
		active_language: null,	// Which language should we display?

		setActive(region) {
			state.active_section = region;

			if( Object.keys(region.languages).indexOf(state.active_language) < 0 )
				state.setLang(region.default_lang);

			update();
		},

		setLang(language) {
			state.active_language = language;

			update();
		},

		show() {
			state.open = true;

			update();
		},

		hide() {
			state.open = false;

			update();
		},

		toggle() {
			state.open = ! state.open;

			update();
		}
	};

	// Try to determine the current active section
	state.regions.forEach(region => {
		Object.keys(region.languages).forEach(language => {
			if( document.location.href.indexOf(language.url) === 0 ) {
				state.active_section = region;
				state.active_language = language;
			}
		});
	});

	const COLOR_GRAY = '#636355';
	const COLOR_GRAY_DARK = 'rgb(79, 77, 63)';
	const COLOR_YELLOW = '#f4dc00';
	const COLOR_YELLOW_LIGHT = '#ffef5b';
	const COLOR_WHITE = '#fff';
	const COLOR_BLACK = '#000';

	// This is a horrible way to handle this
	const ICONS = {
		gb: html`<svg width="24" height="24" fill="none"><path fill="#002780" d="M0 4h24v16H0z"/><path d="M22 20L0 6V4h2l22 14v2h-2z" fill="#fff"/><path d="M24 20L0 5V4l24 15v1z" fill="#CF0921"/><path d="M2 20L24 6V4h-2L0 18v2h2z" fill="#fff"/><path d="M0 20L24 5V4L0 19v1z" fill="#CF0921"/><path fill="#fff" d="M0 10h24v4H0z"/><path fill="#fff" d="M10 4h4v16h-4z"/><path fill="#CF0921" d="M0 11h24v2H0z"/><path fill="#CF0921" d="M11 4h2v16h-2z"/></svg>`,
		ro: html`<svg width="24" height="24" fill="none"><path fill="#FCD20E" d="M0 4h24v16H0z"/><path fill="#002780" d="M0 4h8v16H0z"/><path fill="#CF0921" d="M16 4h8v16h-8z"/></svg>`,
		es: html`<svg width="24" height="24" fill="none"><path fill="#C60B1E" d="M0 4h24v16H0z"/><path fill="#FFC400" d="M0 7h24v10H0z"/></svg>`,
		us: html`<svg width="24" height="24" fill="none"><path fill="#C60B1E" d="M0 4h24v16H0z"/><path d="M0 6h24v2H0V6zM0 10h24v2H0zM0 14h24v2H0zM0 18h24v2H0z" fill="#fff"/><path fill="#002780" d="M0 4h12v8H0z"/></svg>`,
	};


	// Inline <style> tag, for the UI component
	const style = () => html`<style>
		a {
			cursor: pointer;
		}

		.c-rs {
			display: grid;
			grid-template-columns: 2fr 3fr;

			position: relative;
			width: 40rem;
			max-width: calc(100vw - 2rem);

			margin: 1rem auto;

			background: ${COLOR_GRAY};
			color: ${COLOR_WHITE};

			z-index: 1000;

			box-shadow: 0 4px 8px rgba(0,0,0,0.5);

			transform-origin: top;
			transform: scaleY(0);
			opacity: 0;

			transition: transform 0.2s, opacity 0.2s;
		}

		.c-rs--open {
			transform: scaleY(1);
			opacity: 1;
		}

		.c-rs__regions {

		}

		.c-rs__region {
			padding: 0.5rem 1rem;
		}

		.c-rs__region h3 {
			margin: 0;
			font-size: 1.125rem;
			font-weight: bold;
		}

		.c-rs__region a {
			color: ${COLOR_YELLOW};
			line-height: 24px;
			text-decoration: none;
		}

		.c-rs__region svg {
			display: inline-block;
			vertical-align: middle;
			margin-right: 0.5rem;
		}

		.c-rs__region--active {
			background: ${COLOR_GRAY_DARK};
		}

		.c-rs__region--active a {
			color: ${COLOR_WHITE};
		}

		.c-rs__region-languages {
			display: flex;
			justify-content: center;
			text-align: center;
		}

		.c-rs__region-languages > * {
			padding: 0 0.5rem;
			color: ${COLOR_YELLOW};
			text-decoration: underline;
		}

		.c-rs__region-language--active {
			color: ${COLOR_WHITE};
			font-weight: bold;
		}

		.c-rs__details {
			padding: 1rem;
			background: ${COLOR_GRAY_DARK};
		}

		.c-rs__details > * + * {
			margin-top: 1rem;
		}

		.c-rs__details h4 {
			font-size: 1.125rem;
			margin: 0;
			color: white;
		}

		.c-btn {
			position: absolute;

			display: inline-block;
			background: ${COLOR_YELLOW};
			color: ${COLOR_BLACK};
			text-decoration: none;
			font-weight: bold;

			text-align: center;
		}

		.c-btn:hover {
			background: ${COLOR_YELLOW_LIGHT};
		}

		.c-btn--navigate {
			right: 1rem;
			bottom: 1rem;

			padding: 0.5rem 1rem;
			min-width: 9rem;
		}

		.c-btn--close {
			top: 0;
			right: 0;

			padding: 0.5rem;
			width: 2.5rem;
			height: 2.5rem;
		}

		@media(max-width: 700px) {
			.c-rs {
				display: block;
			}

			.c-rs__region {
				border-bottom: 1px solid ${COLOR_BLACK};
			}

			.c-rs__region > * {
				display: inline-block;
			}

			.c-btn--navigate {
				position: static;
			}
		}
	</style>`;

	const languageSelector = (state, region, id) => {
		const lang = region.languages[id];
		const is_active = state.active_section === region && (state.active_language || region.default_lang) === id;

		return html`
			<a
				class="c-rs__region-language ${is_active ? 'c-rs__region-language--active' : ''}"
				@click="${() => state.setLang(id)}"
				@mouseenter="${() => state.setLang(id)}"
			>
				${lang.name}
			</a>`;
	}

	// An individual geographic region
	const regionEntry = (state, region) => html`
		<div
			class="c-rs__region ${state.active_section === region ? 'c-rs__region--active' : ''}"
			@mouseenter="${() => state.setActive(region)}"
		>
			<h3><a
				class="c-rs__region-title"
				@click="${() => state.setActive(region)}"
			>
				${ICONS[region.icon]}
				${region.name}
			</a></h3>

			${Object.keys(region.languages).length > 1 ?
				html`<div class="c-rs__region-languages">
					${Object.keys(region.languages).map(lang => languageSelector(state, region, lang))}
				</div>` : ''}
		</div>`;

	const closeButton = (state) => html`<a class="c-btn c-btn--close" @click="${() => state.hide()}">🗙</a>`;
	
	// Information on the selected region
	const regionDetails = (state, region) => {
		let target = region.languages[state.active_language || region.default_lang || 'en'];

		return html`
			<h4>
				${target.title}
			</h4>
			<div>${target.content}</div>
			<div>
				<a class="c-btn c-btn--navigate" href=${target.url}>${target.button_text}</a>
			</div>`;
	}

	// The dropdown menu as a whole
	const regionSelect = (state) => html`
		<div class="c-rs ${state.open ? 'c-rs--open' : 'c-rs--closed'}">
			${style()}
			<div class="c-rs__regions">${state.regions.map(region => regionEntry(state, region))}</div>
			<div class="c-rs__details">${state.active_section ? regionDetails(state, state.active_section) : 'Click an option to the left'}</div>
			${closeButton(state)}
		</div>`;


	// Set up a shadow dom element to hold the menu
	const root = document.createElement('div');

	root.style.position = 'absolute';
	root.style.top = '0';
	root.style.left = '0';
	root.style.right = '0';
	root.style.height = '0px';

	document.body.appendChild(root);

	// Root update function, responsible for re-drawing the UI
	const update = () => render(regionSelect(state), root);

	update();

	// Open the UI whenever any matching selector is executed
	document.body.querySelectorAll(target).forEach(el => el.addEventListener('click', event => {
		event.preventDefault();
		event.stopPropagation();

		// Calculate the position on-screen to display the menu at
		const bounds = event.target.getBoundingClientRect();

		root.style.top = (bounds.bottom) + 'px';

		state.toggle();
	}));
}
