<template>
	<div class="form-field input-group" :class="{ active: active }">
		<label :for="field.name">{{ $t(`form.${field.name}`) }}</label>
		<input
			v-if="field.type === 'text'"
			:type="field.type"
			:id="field.name"
			:name="field.name"
			@focus="onFocus"
			@blur="onBlur"
			@change="onChange"
			:value="value"
			ref="field"
		/>
		<textarea
			v-if="field.type === 'textarea'"
			:id="field.name"
			:name="field.name"
			@focus="onFocus"
			@blur="onBlur"
			@change="onChange"
			v-html="value"
			ref="field"
		/>
	</div>
</template>

<script>
export default {
	props: ['field'],

	data() {
		return {
			focused: false,
		};
	},

	computed: {
		active() {
			if( this.focused ) return true;
			if( this.value ) return true;

			return false;
		},
		value() {
			return this.field.value;
		},
	},

	methods: {
		onFocus() {
			this.focused = true;
		},

		onBlur() {
			this.focused = false;
		},

		onChange( event ) {
			this.field.set( event.target.value );
		},

		focus() {
			this.$refs.field.focus()
			this.onFocus()
		},
	}
};
</script>
