var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-field input-group", class: { active: _vm.active } },
    [
      _c("label", { attrs: { for: _vm.field.name } }, [
        _vm._v(_vm._s(_vm.$t("form." + _vm.field.name)))
      ]),
      _vm._v(" "),
      _vm.field.type === "text"
        ? _c("input", {
            ref: "field",
            attrs: {
              type: _vm.field.type,
              id: _vm.field.name,
              name: _vm.field.name
            },
            domProps: { value: _vm.value },
            on: { focus: _vm.onFocus, blur: _vm.onBlur, change: _vm.onChange }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.field.type === "textarea"
        ? _c("textarea", {
            ref: "field",
            attrs: { id: _vm.field.name, name: _vm.field.name },
            domProps: { innerHTML: _vm._s(_vm.value) },
            on: { focus: _vm.onFocus, blur: _vm.onBlur, change: _vm.onChange }
          })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }