<template>
	<div class="c-checkbox">
		<input
			type="checkbox"
			class="c-checkbox__input"
			:id="id"
			:name="name"
			:checked="checked"
			ref="input"
			@change="onChange"
		/>
		<label :for="id" class="c-checkbox__label">{{ label }}</label>
	</div>
</template>

<script>
export default {
	props: ['id', 'name', 'checked', 'label'],
	methods: {
		onChange() {
			this.$emit('checked', this.$refs.input.checked);
		},
	}
};
</script>
