
import { record } from './metrics';

const PA_URL = 'https://gss.microservices.m247.com';

export class Panopticon {

	constructor( loadCallback = null ) {
		if( ! global.document ) return;

		// Create the tracking and communications iframe
		const iframe = this._iframe = document.createElement('iframe');

		iframe.style.display = 'none';
		iframe.src = PA_URL + '/pa';

		// Tell the iframe to send us the user's UUID when load finishes
		iframe.addEventListener('load', () => this.post('get_uuid'));

		// Listen for contentWindow messages from the iframe
		window.addEventListener('message', event => {
			if( event.origin !== PA_URL ) return;
			if( ! event.data || typeof event.data !== 'object' ) return;

			const { msg, data } = event.data;

			// Right now, only the 'uuid' message actually does anything, specifically
			// "returning the UUID we requested above"
			if( msg === 'uuid' ) {
				this.uuid = data;

				if( loadCallback && typeof loadCallback === 'function' ) {
					loadCallback( data );
				}
			}
		});

		// Start things up
		document.body.appendChild(iframe);
	}

	post(msg, data) {
		if( ! this._iframe )
			return console.warn('[pa]: Unable to record message, PA tracker not initialized!');

		this._iframe.contentWindow.postMessage({ msg, data }, PA_URL);
	}

}

// Right now this is just a static singleton mostly
export default new Panopticon(uuid => {
	// Record the UUID in the metrics tracking code
	record('uuid', { uuid });
});
