
// Borrowed heavily from https://codepen.io/toddwebdev/pen/yExKoj

export default function nativeSlider(slider, selector_button_prev, selector_button_next) {

	const el = document.querySelector(slider);

	if( ! el ) return console.warn(`Unable to initialize nativeSlider on element ${slider}`);

	let mouse_down, start_x, scroll_left;

	const scrollable_children = el.querySelectorAll('.c-product > *');

	const button_prev = document.querySelector( selector_button_prev );
	const button_next = document.querySelector( selector_button_next );

	const handleButton = scrollAmount => {
		button_prev.blur();
		button_next.blur();

		el.scrollTo({
			left: el.scrollLeft + scrollAmount,
			top: 0,
			behavior: 'smooth'
		});
	};

	button_prev.addEventListener('click', () => {
		handleButton( -250 );
	});

	button_next.addEventListener('click', () => {
		handleButton( 250 );
	});
	
	el.addEventListener('mousedown', event => {
		mouse_down = true;
		start_x = event.pageX - el.offsetLeft;
		scroll_left = el.scrollLeft;

		scrollable_children.forEach(el => el.classList.remove('snap'));
	});

	el.addEventListener('mouseleave', () => {
		mouse_down = false;
	});

	el.addEventListener('mouseup', () => {
		mouse_down = false;

		scrollable_children.forEach(el => el.classList.add('snap'));
	});

	el.addEventListener('mousemove', event => {
		if(! mouse_down) return;
		event.preventDefault();

		const x = event.pageX - el.offsetLeft;
		const walk = x - start_x;

		el.scrollLeft = scroll_left - walk;
	});

	let ticking = false;

	el.addEventListener('scroll', () => {
		if( ! ticking ) {
			requestAnimationFrame(() => {
				button_prev.classList.remove('disabled');
				button_next.classList.remove('disabled');

				el.classList.add('can-scroll-left');
				el.classList.add('can-scroll-right');

				if(el.scrollLeft === 0) {
					button_prev.classList.add('disabled');
					el.classList.remove('can-scroll-left');
				}

				if(el.scrollLeft === el.scrollWidth - el.offsetWidth) {
					button_next.classList.add('disabled');
					el.classList.remove('can-scroll-right');
				}

				ticking = false;
			});
			
			ticking = true;
		}
	}, { passive: true });
}
