// ============================================================================
// Constants
// ============================================================================
/** Version of the client script */
const VERSION = '1.0.0';
/** Where should events from the client be sent? */
const LOGGING_URL = 'https://metrics.m247labs.uk/record-event';
/** Where should the client find the UID-storage iframe? */
const UID_IFRAME_DOMAIN = 'https://metrics.m247labs.uk';
const UID_IFRAME_URL = UID_IFRAME_DOMAIN + '/iframe';
// ============================================================================
// Promise-based page load detection
// ============================================================================
/** This fires when the page has finished loading */
export function onReady() {
    if (document.readyState === 'complete')
        return Promise.resolve(true);
    return new Promise((resolve) => {
        window.addEventListener('load', resolve);
    });
}
// ============================================================================
// UID loading code
// ============================================================================
/** A cached reference to the user's UID */
let uid = null;
let pending_promise = null;
/**
 * Gets the user's tracking UID from an external iframe. This is done to allow a
 * shared ID to be used across multiple domains.
 *
 * This will time out if no ID can be loaded after 5 seconds.
 */
export function getUID() {
    if (uid !== null)
        return Promise.resolve(uid);
    if (pending_promise)
        return pending_promise;
    // This will create the cross-domain iframe, and await the init message
    pending_promise = new Promise((resolve, reject) => {
        // Reject the promise after 5 seconds
        const timeout = setTimeout(() => reject('timeout'), 5 * 1000);
        // Create the iframe itself
        const iframe = document.createElement('iframe');
        // Set up properties
        iframe.setAttribute('importance', 'low');
        //iframe.setAttribute('sandbox', 'allow-scripts')
        iframe.src = UID_IFRAME_URL;
        iframe.style.display = 'none';
        // Fire off an event to the iframe once it's been loaded
        iframe.addEventListener('load', function (event) {
            iframe.contentWindow.postMessage('init', UID_IFRAME_URL);
        });
        // Insert the iframe into the document
        document.body.appendChild(iframe);
        // This hands messages from the tracking iframe
        const handleMessage = function (event) {
            if (event.origin !== UID_IFRAME_DOMAIN)
                return;
            if (event.data.message !== 'uid')
                return;
            // On receiving a message, cancel the pending timeout, and remove this event handler
            clearTimeout(timeout);
            window.removeEventListener('message', handleMessage);
            // Pass back the UID we received from the iframe
            uid = event.data.uid;
            return resolve(event.data.uid);
        };
        window.addEventListener('message', handleMessage, false);
    });
    return pending_promise;
}
// ============================================================================
// Server Communication
// ============================================================================
/**
 * Fires off a request to the server
 *
 * @param event The event to record
 */
function send(event) {
    return navigator.sendBeacon(LOGGING_URL, JSON.stringify(event));
}
// ============================================================================
// Statistics
// ============================================================================
export function getTimings() {
    if (!window.performance || !window.performance.toJSON)
        return Promise.resolve(null);
    return onReady()
        .then(() => {
        const stats = window.performance.getEntriesByType('navigation')[0];
        return {
            lookup: stats.connectEnd,
            server: stats.responseEnd - stats.connectEnd,
            client: stats.loadEventEnd - stats.responseEnd,
            resp_received_at: stats.responseStart,
            dom_ready_at: stats.domComplete,
            load_finished_at: stats.loadEventEnd,
        };
    });
}
/**
 * Records that a pageview has occured
 *
 * @param event
 */
export function pageview({ url, referrer, timings, }) {
    return getUID()
        .then(uid => send({
        version: VERSION,
        type: 'pageview',
        uid,
        url,
        referrer,
        timings,
    }));
}
/**
 * Records that the user has interacted with something on the page
 *
 * @param event
 */
export function interaction({ event, category, data }) {
    return getUID()
        .then(uid => send({
        version: VERSION,
        type: 'interaction',
        uid,
        event,
        category,
        data,
    }));
}
/**
 * Records a generic statistic
 *
 * @param event
 */
export function statistic({ event, category, data }) {
    return getUID()
        .then(uid => send({
        version: VERSION,
        type: 'interaction',
        uid,
        event,
        category,
        data,
    }));
}
export function hookToForm(selector = 'form[method="post"]', field_name = 'Lead_Insights_UID__c') {
    return onReady()
        .then(getUID)
        .then(uid => {
        document.querySelectorAll(selector).forEach(el => {
            const hidden_field = document.createElement('input');
            hidden_field.type = 'hidden';
            hidden_field.value = uid;
            hidden_field.name = field_name;
            el.appendChild(hidden_field);
        });
    });
}
// ============================================================================
// Make everything available globally
// ============================================================================
if (typeof window !== 'undefined') {
    window.leadinsights = window.leadinsights || { pageview, interaction, statistic, getUID, hookToForm, onReady };
}
// ============================================================================
// Runs initial setup
// ============================================================================
/*onReady()
    .then(getTimings)
    .then((timings) => {
        // Fires off an initial pageview event
        pageview({
            url: window.location.href,
            referrer: document.referrer,
            timings,
        })

        // Record that the user has navigated away from the page
        window.addEventListener('beforeunload', () => {
            interaction({ event: 'user_navigated_away', category: 'meta' })
        })
    })

hookToForm()*/
