import { createApp } from './app';

const translation = JSON.parse( document.querySelector('#translation_file').textContent );
const config = JSON.parse( document.querySelector('#metadata').textContent );

const { app } = createApp(translation, config);

app.$mount('.form-block');

// Set up event-handling behaviors for some native elements

import nativeSlider from './behaviors/nativeSlider';
import dataButton from './behaviors/dataButton';

nativeSlider('.c-product-list__products', '.c-product-list [data-action="prev"]', '.c-product-list [data-action="next"]');

document.querySelectorAll('[data-event]').forEach(el => dataButton(el));

import regionSelector from './behaviors/regionSelector';

regionSelector('[data-action="open_region_select"]');

import affix from './behaviors/affix';

document.querySelectorAll('[data-behavior="affix"]').forEach(el => affix(el));

// Set up the product search UI

import Vue from 'vue';
import ProductSearch from './components/ProductSearch.vue';

const ProductSearchApp = Vue.extend(ProductSearch)
const product_search_table_id = document.querySelector('.product-search').dataset.tableId

const product_search_instance = new ProductSearchApp({
	propsData: { table_id: product_search_table_id }
})

product_search_instance.$mount('.product-search')


import RegionListing from './components/RegionListing.vue';

const RegionListingApp = Vue.extend(RegionListing)

const region_list = new RegionListingApp()

region_list.$mount('.region-listing')
