var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-product-search-block" },
    [
      _c("div", { staticClass: "c-product-search-block__title" }, [
        _c("h4", [_vm._v(_vm._s(_vm.product.Name))]),
        _vm._v(" "),
        _c("div", [
          _vm.product.Price
            ? _c("span", { staticClass: "c-product-search-block__price" }, [
                _vm._v("\n\t\t\t\t" + _vm._s(_vm.price_month) + " "),
                _c("span", [_vm._v("/ " + _vm._s(_vm.__("product_search.mo")))])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.product.Price && _vm.price_setup
            ? _c("span", [_c("br")])
            : _vm._e(),
          _vm._v(" "),
          _vm.price_setup
            ? _c("span", { staticClass: "c-product-search-block__price" }, [
                _vm._v("\n\t\t\t\t" + _vm._s(_vm.price_setup) + " "),
                _c("span", [
                  _vm._v(" " + _vm._s(_vm.__("product_search.setup")))
                ])
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("angle-trim", {
        attrs: { "fill-left": "#636355", "line-width": "0" }
      }),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "c-product-search-block__specs" },
        _vm._l(_vm.display_fields, function(value, key) {
          return _c(
            "li",
            { key: key, staticClass: "c-product-search-block__spec-entry" },
            [
              _c("span", [_vm._v(_vm._s(key))]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(value))])
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("angle-trim", {
        attrs: { "line-width": "2", "line-color": "lightgrey" }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "c-product-search-block__cta" }, [
        _c(
          "a",
          {
            staticClass: "c-button",
            attrs: { "data-name": _vm.product.Name },
            on: { click: _vm.showContactForm }
          },
          [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(_vm.__("product_search.configure_now")) +
                "\n\t\t"
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }