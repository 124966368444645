<template>
	<section class="c-product-search">
		<form
			v-if="! disable_filter"
			class="c-product-search__form"
		>
			<div>
				<h3>{{__('product_search.server_type')}}</h3>

				<c-checkbox
					v-for="(term, i) in ['high_memory', 'high_performance', 'high_storage']"
					:key="i"
					:id="`checkbox_${term}`"
					:name="`checkbox_${term}`"
					:label="__('product_search.' + term)"
					checked="true"
					@checked="value => setSearchKeyword('type', term, value)"
				/>
			</div>
			<div>
				<h3>{{__('product_search.server_use_case')}}</h3>

				<div class="l-grid l-grid--2 l-grid--gap-1 l-grid--break-small">
					<div>
						<c-checkbox
							v-for="(term, i) in ['small_business', 'ecommerce']"
							:key="i"
							:id="`checkbox_${term}`"
							:name="`checkbox_${term}`"
							:label="__('product_search.' + term)"
							checked="true"
							@checked="value => setSearchKeyword('use_case', term, value)"
						/>
					</div>
					<div>
						<c-checkbox
							v-for="(term, i) in ['cloud_computing', 'web_hosting']"
							:key="i"
							:id="`checkbox_${term}`"
							:name="`checkbox_${term}`"
							:label="__('product_search.' + term)"
							checked="true"
							@checked="value => setSearchKeyword('use_case', term, value)"
						/>
					</div>
				</div>
			</div>
			<div v-if="should_show_price_filter">
				<h3>{{__('product_search.server_price_range')}}</h3>

				<c-checkbox
					v-for="(term, i) in ['below_100', '100_to_200', 'above_200']"
					:key="i"
					:id="`checkbox_${term}`"
					:name="`checkbox_${term}`"
					:label="__('product_search.' + term)"
					checked="true"
					@checked="value => setSearchKeyword('price', term, value)"
				/>
			</div>
		</form>

		<div class="c-product-search__results">
			<product-search-block
				v-for="product in truncated_products"
				:key="product.id"
				:product="product"
			/>

			<div
				v-if="truncated_products.length < selected_products.length"
				class="c-product-search__show-more"
			>
				<a class="c-button" @click="() => this.show_more_toggled = !this.show_more_toggled">
					<span>{{__('product_search.show_more')}}</span>
				</a>
			</div>
		</div>

		<div v-if="state === 'loaded' && ! selected_products.length">
			<h3>{{__('product_search.no_results')}}</h3>

			<p>{{__('product_search.try_more_parameters')}}</p>
		</div>

		<div v-if="state === 'error'">
			<h3>{{__('product_search.unable_to_load')}}</h3>

			<p>{{__('product_search.the_error_encountered_was')}} "{{error}}"</p>
		</div>
	</section>
</template>

<script>
import ProductSearchBlock from './ProductSearchBlock.vue';
import CCheckbox from './CCheckbox.vue';
import localize from '../mixins/localize';

const API_ROUTE = 'https://m247.com/wp-json/m247/v1/products/table/';
const MAX_SEARCH_RESULTS = 8;

export default {
	components: {ProductSearchBlock, CCheckbox},
	props: ['table_id', 'disable_filter'],
	mixins: [localize],
	data() {
		return {
			products: [],
			state: 'init',
			error: null,
			filter: {
				type: ['high_memory', 'high_performance', 'high_storage'],
				use_case: ['small_business', 'ecommerce', 'cloud_computing', 'web_hosting'],
				price: ['below_100', '100_to_200', 'above_200'],
			},
			show_more_toggled: false,
		}
	},
	created() {
		this.state = 'loading';
		fetch(API_ROUTE + this.table_id)
			.then(data => data.json())
			.then(data => {
				console.dir(data)
				
				this.state = 'loaded';
				this.products = data.products;
			})
			.catch(error => {
				this.state = 'error';
				this.error = error;
			});
	},
	methods: {
		setSearchKeyword(category, word, value) {
			word = word
				.toLowerCase()
				.replace(/[^a-zA-Z0-9_ ]+/g, '')
				.replace(/ /g, '_');
			if( ! value ) {
				this.filter[category] = this.filter[category].filter(keyword => keyword !== word);
			}
			else {
				if( ! this.filter[category].includes(word) ) {
					this.filter[category].push(word);
				}
			}
		},
	},
	computed: {
		selected_products() {
			if( this.disable_filter ) return this.products;
			const array_union = (a, b) => {
				for ( const item of a ) {
					if( b.includes(item) ) return true;
				}
				return false;
			}
			const options = this.products.filter(product => {
				if( product.keywords && ! array_union( this.filter.use_case, product.keywords ) ) return false;
				if( product.type && ! array_union( this.filter.type, product.type ) ) return false;
				if( product.Price ) {
					const parsed = parseFloat(product.Price);
					// I feel like there's probably a better way to write this...
					if( parsed < 100 && this.filter.price.includes('below_100') ) return true;
					if( parsed >= 100 && parsed <= 200 && this.filter.price.includes('100_to_200') ) return true;
					if( parsed >= 200 && this.filter.price.includes('above_200') ) return true;
					return false;
				}
				return true;
			});
			return options;
		},
		truncated_products() {
			if( this.show_more_toggled ) return this.selected_products;
			return this.selected_products.slice(0, MAX_SEARCH_RESULTS);
		},
		// on UK, all prices will be blank, so the filter for them doesn't really make any sense
		should_show_price_filter() {
			return this.products.some(product => product.Price && product.Price !== '0');
		},
	},
};
</script>
