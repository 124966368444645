var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "c-angle-trim",
      attrs: {
        viewBox: "0 0 100 100",
        width: _vm.width,
        height: "100%",
        preserveAspectRatio: "none"
      }
    },
    [
      _vm.fillLeft
        ? _c("polygon", {
            attrs: { points: "0,0 0,100, 100,0", fill: _vm.fillLeft }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.fillRight
        ? _c("polygon", {
            attrs: { points: "100,0 100,100, 0,100", fill: _vm.fillRight }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.lineWidth
        ? _c("line", {
            attrs: {
              x1: "0",
              y1: "100",
              x2: "100",
              y2: "0",
              "stroke-width": _vm.lineWidth,
              stroke: _vm.lineColor,
              "stroke-dasharray": _vm.lineDashArray,
              "vector-effect": "non-scaling-stroke"
            }
          })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }