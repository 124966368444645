import en from '../localization/terms_en.yml';
import ro from '../localization/terms_ro.yml';
import es from '../localization/terms_es.yml';

const LANGUAGE = Symbol('current_language');
const TERMS = Symbol('localization_terms');

export default {
	data() {
		return {
			[LANGUAGE]: document.documentElement.lang,
			[TERMS]: { en, ro, es },
		}
	},
	beforeMount() {
		this.$data[LANGUAGE] = document.documentElement.lang;
	},
	computed: {
		language() {
			if( ! this.$data[LANGUAGE] ) return 'unknown';

			if( this.$data[LANGUAGE].includes('en') ) return 'en';
			if( this.$data[LANGUAGE].includes('ro') ) return 'ro';
			if( this.$data[LANGUAGE].includes('es') ) return 'es';

			return 'unknown';
		},
		localization_terms() {
			return this.$data[TERMS][ this.language ] || {};
		},
	},
	methods: {
		__(string) {
			const parts = string.split('.');
			const terms = this.localization_terms;

			// Recursively search an object for a specified key
			const search = (parts, level) => {
				const current_target = parts.shift();
				const next_level = level[current_target];

				if( ! next_level ) return null;
				if( parts.length === 0 ) return next_level;

				return search(parts, next_level);
			}

			return search(parts, terms) || string;
		},
	},
};
