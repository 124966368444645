var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "c-region-listing l-stack l-stack--compact" },
    [
      _c("div", [
        _c(
          "div",
          {
            staticClass: "c-form-field",
            staticStyle: {
              width: "auto",
              "min-width": "20rem",
              display: "inline-block"
            },
            attrs: {
              "data-is-focused": _vm.filter_has_focus,
              "data-hide-placeholder": _vm.filter_hide_placeholder
            }
          },
          [
            _c(
              "label",
              {
                staticClass: "c-form-field__label",
                attrs: { for: "region-list-filter" }
              },
              [_vm._v("Search")]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.filter,
                  expression: "filter"
                }
              ],
              staticClass: "c-form-field__input",
              attrs: { type: "text", id: "region-list-filter" },
              domProps: { value: _vm.filter },
              on: {
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.filter = $event.target.value
                  },
                  _vm.updateFilter
                ],
                focus: _vm.setFilterFocus,
                blur: _vm.setFilterBlur
              }
            })
          ]
        )
      ]),
      _vm._v(" "),
      _c("table", { staticClass: "c-table c-table--accent" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.rows, function(row) {
            return _c("tr", { key: row.address }, [
              _c("td", [_vm._v(_vm._s(row.region))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(row.country))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(row.data_centre))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(row.address))])
            ])
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "l-flex l-flex--space-between" }, [
        _c(
          "button",
          {
            staticClass: "c-button",
            style: { opacity: _vm.can_go_back ? 1 : 0.5 },
            attrs: { disabled: !_vm.can_go_back },
            on: { click: _vm.prev }
          },
          [_vm._v("« Prev")]
        ),
        _vm._v(" "),
        _c("span", [
          _vm._v(
            "\n\t\t\tPage " +
              _vm._s(_vm.page) +
              " of " +
              _vm._s(_vm.pages) +
              "\n\t\t"
          )
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "c-button",
            style: { opacity: _vm.can_go_forward ? 1 : 0.5 },
            attrs: { disabled: !_vm.can_go_forward },
            on: { click: _vm.next }
          },
          [_vm._v("Next »")]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Region")]),
        _vm._v(" "),
        _c("th", [_vm._v("Country")]),
        _vm._v(" "),
        _c("th", [_vm._v("Data centre")]),
        _vm._v(" "),
        _c("th", [_vm._v("Address")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }