'use strict';

import reqwest from 'reqwest';

import {recordPageview, recordEvent, event_categories} from '../util/metrics';
import panopticon from '../util/panopticon';
import { getUID } from '../lib/lead-insights'

const API_PATH = 'https://leads.microservices.m247.com';

const DEFAULT_FIELDS = [
	'name',
	'email',
	'phone',
	'company',
	'postcode',
];

/**
 * Creates a representation of the data state of a contact form.  This is a bit inelegant, as it
 * mixes data state and data mutation functions, but it's probably alright as this is a relatively
 * lightweight feature overall.  Plus, it saves the overhead of including Vuex.
 *
 * @argument {object} [config] - A list of fields to enable to disable
 * 
 * @returns {object} A representation of a form state
 */
export default function({ components, site_name, locale, lead_destination, }) {

	// This is a janky workaround to the fact that this is synchronous code.
	// However, it should generally be defined by the time anything relevant
	// happens.
	let leadinsights_uid;
	getUID().then(uid => leadinsights_uid = uid)

	const field = (name, type='text') => {
		const f = {
			// Basic parameters
			name,
			type,

			// Value accessors
			value: null,
			set: val => f.value = val,
		};

		return f;
	};

	const state = {

		// Current state, will be one of 'idle', 'loading', 'errored', 'completed'
		state: 'idle',

		// Form fields
		fields: {},

		gdpr_accepted: false,	// Has the GDPR acceptance checkbox been hit?

		gdpr_notice_shown: false,	// Should a prompt asking the user to click the GDPR checkbox appear?

		last_error: null,		// The last form submission error that occured, if any

		lead_source: null,		// The lead source to include when the form is submitted

		// Operations that mutate the form state
		functions: {

			// Checks whether the current form values are valid
			validate() {
				// TODO: implement
				return true;
			},

			// Serializes the form's values into a nice JSON object
			getValues() {
				const res = {};

				for( const field in state.fields ) {
					res[field] = state.fields[field].value;
				}

				return res;
			},

			toggleGDPRAcceptance() {
				state.gdpr_notice_shown = false;
				state.gdpr_accepted = !state.gdpr_accepted;
			},

			// Attempts to submit the form
			submit() {
				if( ! state.gdpr_accepted ) {
					state.gdpr_notice_shown = true;
					return;
				}
				if( ! state.functions.validate() ) return;

				state.state = 'loading';

				const form_values = state.functions.getValues();
				const lead = { ...form_values };

				delete lead.enquiry; // This should be sent across in the options, rather than in the lead data

				lead.leadinsights_uid = leadinsights_uid;

				recordEvent(event_categories.landing_page_forms, 'form_submitted');

				reqwest({
					url: API_PATH + '/lead',
					type: 'json',
					method: 'post',
					contentType: 'application/json',
					data: JSON.stringify({
						lead,
						source: site_name ? site_name + document.location.pathname : `${document.location.host}${document.location.pathname}`,
						variant: locale || '(unknown locale)',
						note: form_values.enquiry,
						gss_uuid: panopticon.uuid || null,
						destination: lead_destination,
					}),
				})
					.then(resp => {
						state.state = 'success';
						
						// Send a notification to Google Analytics
						recordEvent(event_categories.landing_page_forms, 'completed');
					})
					.catch(err => {
						state.state = 'error';
						console.error(err);

						// Try to parse something useful out of the failure message
						if( err.response ) {
							try {
								state.last_error = JSON.parse(err.response);
							}
							catch(ex) {
								state.last_error = err.response;
							}
						}
						else {
							state.last_error = `An unknown error occured.
								This is most likely a temporary problem - click below to try again.
								If the problem persists, let us know by sending an email to ticket@m247.com.`;
						}

						// Get a label for the specific error event, to sent to GA
						const label = typeof state.last_error === 'object' ?
							state.last_error.type || state.last_error.name || state.last_error.message :
							state.last_error.substr(0,100);

						// Flag the form submission failure with GA
						recordEvent(event_categories.landing_page_forms, 'errored', label);
					});

				// Send a notification to the GSS system, with the user's email address
				const email = state.fields.email.value;

				if( email )
					panopticon.post('set_email', email);
			},
		},

	};


	(typeof components === 'object' && components.fields_enabled ? components.fields_enabled : DEFAULT_FIELDS).forEach(f => {
		if( typeof f === 'string' )
			state.fields[f] = field(f);
		else if( typeof f === 'object' && f.name && f.type )
			state.fields[f.name] = field(f.name, f.type);
		else
			console.warn('Encountered form field in config with unknown parameters:', f);
	});

	return state;

}
