var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    { staticClass: "form-block block" },
    [
      _vm._l(_vm.fields, function(field) {
        return _c("form-field", {
          key: field.name,
          ref: "field_" + field.name,
          refInFor: true,
          attrs: { type: "text", field: field }
        })
      }),
      _vm._v(" "),
      _c("div", { staticClass: "checkbox-group toc-confirmation" }, [
        _c("span", { staticClass: "checkbox" }, [
          _c("input", {
            attrs: { type: "checkbox", name: "confirm_toc", id: "confirm_toc" },
            on: { click: _vm.toggleGDPR }
          }),
          _vm._v(" "),
          _c("span", { staticClass: "tick-mark" }, [_vm._v("✔")])
        ]),
        _vm._v(" "),
        _c("label", {
          attrs: { for: "confirm_toc" },
          domProps: { innerHTML: _vm._s(_vm.$t("gdpr.agree_to_toc")) }
        })
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.state === "idle",
              expression: "state === 'idle'"
            }
          ],
          staticClass: "c-button c-button--angled",
          attrs: { type: "button" },
          on: { click: _vm.submit }
        },
        [_c("span", [_vm._v(_vm._s(_vm.$t("buttons.submit")))])]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.gdpr_notice_shown === true,
              expression: "gdpr_notice_shown === true"
            }
          ],
          staticClass: "overlay gdpr-checkbox"
        },
        [
          _c("h3", [_vm._v(_vm._s(_vm.$t("gdpr.gdpr_agreement_prompt")))]),
          _vm._v(" "),
          _c(
            "svg",
            {
              staticClass: "form-arrow",
              attrs: {
                width: "100",
                height: "100",
                viewBox: "0 0 100 100",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg"
              }
            },
            [
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d:
                    "M64.885 11a1 1 0 0 0-1-1h-19a1 1 0 0 0-1 1v37a1 1 0 0 1-1 1H31.002a1 1 0 0 0-.761 1.648l23.382 27.458a1 1 0 0 0 1.523 0l23.383-27.458A1 1 0 0 0 77.767 49H65.885a1 1 0 0 1-1-1V11z",
                  fill: "#F4DC00"
                }
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M73.692 55.648l-1.142-.972 1.142.972zM48.786 83.106l-1.142.973 1.142-.973zm1.523 0l1.142.973-1.142-.973zM25.404 55.648l1.142-.972-1.142.972zM40.048 16.5h19v-3h-19v3zm.5 36.5V16h-3v37h3zm-14.383 2.5h11.883v-3H26.165v3zm23.763 26.633L26.546 54.676l-2.284 1.945 23.382 27.458 2.284-1.945zM72.55 54.676L49.167 82.133l2.284 1.946L74.834 56.62l-2.284-1.945zm-11.502.824H72.93v-3H61.048v3zm-2.5-39.5v37h3V16h-3zm2.5 36.5a.5.5 0 0 1 .5.5h-3a2.5 2.5 0 0 0 2.5 2.5v-3zm13.786 4.12c1.382-1.622.228-4.12-1.904-4.12v3a.5.5 0 0 1-.38-.824l2.284 1.945zM47.644 84.08a2.5 2.5 0 0 0 3.807 0l-2.284-1.945a.5.5 0 0 1 .761 0l-2.284 1.945zM26.165 52.5c-2.132 0-3.286 2.498-1.903 4.12l2.284-1.944a.5.5 0 0 1-.38.824v-3zm11.383.5a.5.5 0 0 1 .5-.5v3a2.5 2.5 0 0 0 2.5-2.5h-3zm21.5-36.5a.5.5 0 0 1-.5-.5h3a2.5 2.5 0 0 0-2.5-2.5v3zm-19-3a2.5 2.5 0 0 0-2.5 2.5h3a.5.5 0 0 1-.5.5v-3z",
                  fill: "#595E53"
                }
              })
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.state === "loading",
              expression: "state === 'loading'"
            }
          ],
          staticClass: "overlay loading"
        },
        [
          _c(
            "svg",
            {
              staticClass: "load-spinner",
              attrs: {
                width: "100",
                height: "100",
                viewBox: "0 0 100 100",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg"
              }
            },
            [
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d:
                    "M42 5.70898C20.9637 9.48345 5 27.8775 5 50C5 74.8528 25.1472 95 50 95C74.8528 95 95 74.8528 95 50C95 27.8775 79.0363 9.48345 58 5.70898V15.9185C73.4748 19.5368 85 33.4225 85 50C85 69.33 69.33 85 50 85C30.67 85 15 69.33 15 50C15 33.4225 26.5252 19.5368 42 15.9185V5.70898Z",
                  fill: "#F4DC00"
                }
              })
            ]
          ),
          _vm._v(" "),
          _c("h3", [_vm._v(_vm._s(_vm.$t("loading")))])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.state === "error",
              expression: "state === 'error'"
            }
          ],
          staticClass: "overlay error"
        },
        [
          typeof _vm.last_error === "string"
            ? [
                _c("h3", [
                  _vm._v(_vm._s(_vm.$t("errors.something_went_wrong")))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "caption" }, [
                  _vm._v(_vm._s(_vm.last_error))
                ])
              ]
            : _vm.last_error !== null && typeof _vm.last_error === "object"
            ? [
                _vm.last_error.type === "ERR_VALIDATION"
                  ? [
                      _c("h3", [
                        _vm._v(_vm._s(_vm.$t("errors.validation_error")))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "caption" }, [
                        _vm._v(
                          _vm._s(_vm.$t("errors.validation_error_caption"))
                        )
                      ]),
                      _vm._v(" "),
                      _vm.last_error.errors
                        ? _c(
                            "ul",
                            _vm._l(_vm.last_error.errors, function(error) {
                              return _c("li", { key: error.field }, [
                                _c("strong", [_vm._v(_vm._s(error.field))]),
                                _vm._v(
                                  " " + _vm._s(error.error) + "\n\t\t\t\t\t"
                                )
                              ])
                            }),
                            0
                          )
                        : _vm._e()
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.last_error.type === "ERR_SF"
                  ? [
                      _vm.last_error.name === "DUPLICATES_DETECTED"
                        ? [
                            _c("h3", [
                              _vm._v(_vm._s(_vm.$t("errors.duplicate_lead")))
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "caption" }, [
                              _vm._v(
                                _vm._s(_vm.$t("errors.duplicate_lead_caption"))
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.$t("errors.duplicate_lead_subtitle"))
                              )
                            ])
                          ]
                        : [
                            _c("h3", [
                              _vm._v(_vm._s(_vm.$t("errors.system_error")))
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "caption" }, [
                              _vm._v(_vm._s(_vm.last_error.message))
                            ])
                          ]
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.last_error.type === "ERR_RATELIMIT"
                  ? [
                      _c("h3", [
                        _vm._v(_vm._s(_vm.$t("errors.rate_limit_error")))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "caption" }, [
                        _vm._v(_vm._s(_vm.last_error.message))
                      ]),
                      _vm._v(" "),
                      _vm.last_error.retry_after
                        ? _c("div", { staticClass: "caption" }, [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(
                                  _vm.$t("errors.rate_limit_error_caption", [
                                    _vm.last_error.retry_after
                                  ])
                                ) +
                                "\n\t\t\t\t"
                            )
                          ])
                        : _vm._e()
                    ]
                  : _vm._e()
              ]
            : [
                _c("h3", [_vm._v(_vm._s(_vm.$t("errors.unknown_error")))]),
                _vm._v(" "),
                _c("div", { staticClass: "caption" }, [
                  _vm._v("\n\t\t\t\t" + _vm._s(_vm.last_error) + "\n\t\t\t")
                ])
              ],
          _vm._v(" "),
          _c(
            "button",
            { attrs: { type: "button" }, on: { click: _vm.reset } },
            [_vm._v(_vm._s(_vm.$t("buttons.try_again")))]
          )
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.state === "success",
              expression: "state === 'success'"
            }
          ],
          staticClass: "overlay success"
        },
        [
          _c("h3", [_vm._v(_vm._s(_vm.$t("success.success_title")))]),
          _vm._v(" "),
          _c("div", { staticClass: "caption" }, [
            _vm._v(_vm._s(_vm.$t("success.success_caption")))
          ])
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }